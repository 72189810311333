/* purgecss start ignore */
.label-option-container {
    box-shadow: 0px 5px 10px rgba(77, 77, 77, 0.1);
    border-radius: 5px;
    padding: 16px;
    position: relative;
    cursor: pointer;
    border: 2px solid transparent;

    &.selected {
        border-color: #050593;
    }

    .check-icon {
        position: absolute;
        right: 8px;
        top: 8px;
    }

    .label-option {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-weight: bold;

        .label-size-cm {
            color: #050593;
            font-size: 20px;
        }
        .label-size-in {
            color: rgba(89, 89, 89, 0.5);
            font-size: 14px;
        }
        .label-size-name {
            color: #595959;
            font-size: 16px;
            margin-top: 8px;
        }
    }
}
/* purgecss end ignore */