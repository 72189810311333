.slide-bottom {
  -webkit-animation: slide-bottom 1.5s linear forwards;
  animation: slide-bottom 1.5s linear forwards;
}

@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
  }
}
@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
  }
}


.slide-top {
  -webkit-animation: slide-top 1.5s linear forwards;
  animation: slide-top 1.5s linear forwards;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}