/* purgecss start ignore */
.status-update-item-section:not(:first-child) {
    border-top: 1px solid #E8E8E8;
    padding-top: 1.5rem;
}

.status-update-item {
    .status-update {
        margin-bottom: 1.5rem;
    }
    &:last-child {
        .status-update {
            margin-bottom: 0;
        }
    }
}
/* purgecss end ignore */