.link-container{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2em;
}
.go-back-btn{
  border: none;
  padding: 0px;
  margin-bottom: 10px;
  font-weight: 600;
  box-shadow: -3px 6px 5px #f1f1f1;
}
.modal-title{
  .ant-modal-header {
    .ant-modal-title {
      font-size: 20px;
      margin-bottom: -10px;
    }
  }
}
