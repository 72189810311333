/* purgecss start ignore */
.terms-modal {
  width: 800px;
  height: 665px;

  .ant-btn {
    height: 40px !important;
  }

  .ant-modal-body {
    margin-left: 33px;
    margin-right: 35px;
    margin-top: 27px;
    // border: 1px solid rgba(77, 77, 77, 0.2);
    padding: 8px;
    height: 392px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(77, 77, 77, 0.1);
      border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: rgba(77, 77, 77, 0.2);
    }
  }

  .ant-modal-close-x {
    padding-right: 35px;
  }

  .ant-modal-footer {
    margin-left: 33px;
    margin-right: 35px;
    padding: 0;
    padding-top: 25px;
    padding-bottom: 34px;

    .ant-checkbox-wrapper {
      margin-bottom: 4px;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #050593;
    }

    .ant-checkbox + span {
      font-size: 16px;
      line-height: 19px;
      color: #4d4d4d;
    }
  }

  .ant-modal-header {
    padding-top: 32px;
    padding-bottom: 24px;
    margin-left: 33px;
    margin-right: 36px;
    border-bottom: 1px solid rgba(77, 77, 77, 0.2);
    padding-left: 0;
    padding-right: 0;
  }
}
/* purgecss end ignore */

.terms-modal__title {
  color: #4d4d4d;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  line-height: 22px;
  margin-bottom: 0;
}

.terms-modal__error {
  font-size: 14px;
  line-height: 17px;
  color: #e31920;
  margin-top: 4px;
  margin-bottom: 11px;
}

.terms-modal__error--hidden {
  visibility: hidden;
}

/* purgecss start ignore */
.terms-cancel-dialog {
  height: 192px;

  .ant-btn {
    height: 40px !important;
  }

  .ant-modal-body {
    padding: 24px;
    padding-top: 27px;
  }

  .ant-modal-confirm-body {
    i svg {
      width: 25px;
      height: 25px;
    }

    .ant-modal-confirm-title {
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      color: #4d4d4d;
      margin-bottom: 16px;
    }

    .ant-modal-confirm-content {
      font-size: 18px;
      line-height: 22px;
      color: #4d4d4d;
    }
  }
}
/* purgecss end ignore */

.terms-and-conditions {
  padding: 6px 17px;
  line-height: 24px;
  color: #000000;
  max-width: 1086px;

  p, strong {
    font-size: 14px;
  }

  strong {
    font-weight: bold;
  }
}

.terms-and-conditions--page {
  border: 1px solid rgba(77, 77, 77, 0.2);
  height: 723px;
  overflow-y: scroll;
}

.terms-modal-no-animation {
  top: 0 !important;
}